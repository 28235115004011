document.addEventListener("DOMContentLoaded", function () {
  const toggleButton = document.getElementById("toggle-colors");

  // Vérifiez si l'inversion des couleurs est activée dans localStorage
  if (localStorage.getItem("invertColors") === "true") {
    document.body.classList.add("invert-colors");
  }

  toggleButton.addEventListener("click", function () {
    // Inversez l'état de la classe invert-colors
    document.body.classList.toggle("invert-colors");

    // Enregistrez l'état actuel dans localStorage
    if (document.body.classList.contains("invert-colors")) {
      localStorage.setItem("invertColors", "true");
    } else {
      localStorage.setItem("invertColors", "false");
    }
  });
});
